import React, { useEffect, useState } from "react";
import { IoIosArrowDown, IoMdImage } from "react-icons/io";
import axios from "axios";
import WhatsappContact from "../components/contact";

const Spinner = () => (
  <div className="w-10 h-10 border-4 border-orange-500 border-t-transparent rounded-full animate-spin"></div>
);

const FiveInOne = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageError, setImageError] = useState({});
  const [selectedItems, setSelectedItems] = useState({
    Jacket: null,
    sleeves: null,
    Bag: null,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);

  const handleNavigate = (url) => {
    window.location.href = url;
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          "https://saarconcept.net/api/products-with-filters/"
        );
        setProducts(response.data.products);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleImageError = (index, i) => {
    setImageError((prev) => ({
      ...prev,
      [`${index}-${i}`]: true,
    }));
  };

 const handleAddToCart = (product, item) => {
  const category = product.category.name;

  if (!["Jacket", "sleeves", "Bag"].includes(category)) {
    return;
  }

  const isSelected =
    selectedItems[category]?.product.id === product.id &&
    selectedItems[category]?.item.color.name === item.color.name;

  if (isSelected) {
    // Deselect the item
    setSelectedItems((prev) => ({
      ...prev,
      [category]: null,
    }));
    return;
  }

  if (["sleeves", "Bag"].includes(category)) {
    const existingColor =
      selectedItems.sleeves?.item.color.name || selectedItems.Bag?.item.color.name;
    if (existingColor && existingColor !== item.color.name) {
      alert("The sleeves and bag must be the same color.");
      return;
    }
  }

  const newSelectedItems = { ...selectedItems, [category]: { product, item } };
  setSelectedItems(newSelectedItems);

  if (Object.values(newSelectedItems).every((selection) => selection)) {
    // All three items selected, proceed to cart
    const cartItems = Object.values(newSelectedItems).map(({ product, item }) => ({
      id: product.id,
      name: product.name,
      slug: product.slug,
      color: item.color.name,
      size: "S",
      base_price: item.base_price,
      images: item.images && item.images.length > 0 ? item.images : [],
    }));

    localStorage.setItem("cart", JSON.stringify(cartItems));
    handleNavigate("/cart");
  }
};


  return (
    <div className="bg-[#FDF5F2] flex flex-col items-center justify-center min-h-screen p-4 space-y-8">
      <div className="flex flex-col items-center mt-0 mb-0">
        <h1 className="text-4xl font-bold font-gothamBold text-[#3D291D]">5 in 1</h1>
        <p className="text-sm sm:text-md text-[#3D291D] text-center mb-0">
          <span className="font-gothamLight">You can choose</span>
          <span className="font-gothamBold"> 1 Jacket, 1 sleeves, and 1 pop bag.</span>
        </p>
        <p className="font-gothamBold text-xs sm:text-xs text-[#7DDED9] text-center mt-0">
          The sleeves and pop bag should be the same color.
        </p>
        <IoIosArrowDown className="text-[#3D291D] text-3xl sm:text-4xl mt-2" />
      </div>

     {loading ? (
  <div className="flex flex-col justify-center items-center h-64">
    <Spinner />
  </div>
) : (
  products
    .filter((product) =>
      ["Jacket", "sleeves", "Bag"].some((category) =>
        product.category.name.includes(category)
      )
    )
    .sort((a, b) => {
      // Define the desired order
      const order = ["Jacket", "Bag", "sleeves"];
      return order.indexOf(a.category.name) - order.indexOf(b.category.name);
    })
    .map((product, index) => (
      <div key={index} className="bg-[#FDF5F2] p-0 w-full max-w-xl sm:max-w-5xl">
        <div className="flex justify-center space-x-0 sm:space-x-6 lg:space-x-8 xs:px-6 sm:px-8">
          {product.product_colors.map((colorData, i) => (
            <div
              key={i}
              className="flex flex-col items-center w-1/3 px-1 sm:px-2"
            >
              <div className="w-full aspect-square flex items-center justify-center overflow-hidden">
                {imageError[`${index}-${i}`] || !colorData.images.length ? (
                  <IoMdImage className="text-4xl text-gray-400" />
                ) : (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAddToCart(product, colorData);
                    }}
                    className="w-full h-full flex items-center justify-center"
                  >
                    <img
                      src={colorData.images[0].image}
                      alt={`${product.name} ${colorData.color.name}`}
                      className="w-full h-full object-cover"
                      loading="lazy"
                      onError={() => handleImageError(index, i)}
                    />
                  </a>
                )}
              </div>
              <p className="text-[10px] sm:text-lg font-gothamBold text-[#3D291D] mt-1 text-center w-full truncate max-w-full">
                {colorData.color.name} {product.name}
              </p>
             {/* Button for Desktop */}
<button
  className={`hidden sm:inline-block px-5 py-2 text-sm border-1 ${
    selectedItems[product.category.name]?.product.id === product.id &&
    selectedItems[product.category.name]?.item.color.name === colorData.color.name
      ? "bg-[#FA8F21] text-white border-transparent"
      : "border-[#3D291D] text-[#3D291D] hover:bg-[#FA8F21] hover:text-white hover:border-transparent"
  } font-gothamBold transition duration-200 rounded-3xl`}
  onClick={() => handleAddToCart(product, colorData)}
>
  Choose
</button>

{/* Button for Mobile */}
<button
  className={`inline-block sm:hidden px-3 py-1 text-sm border-1 ${
    selectedItems[product.category.name]?.product.id === product.id &&
    selectedItems[product.category.name]?.item.color.name === colorData.color.name
      ? "bg-[#FA8F21] text-white border-transparent"
      : "border-[#3D291D] text-[#3D291D] hover:bg-[#FA8F21] hover:text-white hover:border-transparent"
  } font-gothamBold transition duration-200 rounded-3xl`}
  onClick={() => handleAddToCart(product, colorData)}
>
  Choose
</button>

            </div>
          ))}
        </div>
      </div>
    ))
)}

      <div className="fixed bottom-32 right-4 lg:right-8 z-50">
        <WhatsappContact />
      </div>
      <hr className="w-full h-px bg-[#3D291D] mt-8 " />
    </div>
  );
};

export default FiveInOne;
