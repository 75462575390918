import React, { useEffect, useState } from "react";
import { GoTrash } from "react-icons/go";
import { HiOutlineMinus, HiOutlinePlus } from "react-icons/hi";
import { IoIosArrowDown } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const AddCart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [promoCode, setPromoCode] = useState('');
  const [isValid, setIsValid] = useState(null);
  const navigate = useNavigate();
  const handleProductClick = (slug, colorName) => {
    console.log("Navigating to:", slug, colorName); // Debugging
    navigate(`/productDetail/${slug}?color=${colorName}`);
  };
  
  const handlePromoCodeChange = (e) => {
    setPromoCode(e.target.value);
  };
  const validatePromoCode = async () => {
    if (!promoCode) return;
  
    const encodedPromoCode = encodeURIComponent(promoCode);
  
    try {
      const response = await fetch(`https://saarconcept.net/api/check-promo-code/?code=${encodedPromoCode}`);
      
      if (!response.ok) {
        throw new Error('Promo code validation failed');
      }
  
      const data = await response.json();
  
      if (data.exists && data.is_valid) {
  
        // Apply discount to each item
        const updatedItems = cartItems.map((item) => ({
          ...item,
          discount: data.details.discount_value, // Store the discount for each item
        }));
  
        setCartItems(updatedItems); // Update the cart with discounts
        setIsValid(true);
      } else {
        alert('Promo code is invalid or expired.');
        setIsValid(false);
      }
    } catch (error) {
      alert('An error occurred while validating the promo code.');
      setIsValid(false);
    }
  };
  
  useEffect(() => {
    try {
      const storedCartItems = JSON.parse(localStorage.getItem("cart")) || [];
      const updatedItems = storedCartItems.map((item) => {
        const defaultSizes = [
          { size: "S", quantity: 0 },
          { size: "M", quantity: 0 },
          { size: "L", quantity: 0 },
        ];
  
        // If a size is stored, set its quantity to 1
        const sizeStored = item.size || null; // Assuming size is stored as a key in each item
        const selectedSizes = defaultSizes.map((sizeObj) => ({
          ...sizeObj,
          quantity: sizeStored === sizeObj.size ? 1 : 0,
        }));
  
        return {
          ...item,
          images: Array.isArray(item.images) ? item.images : [],
          selectedSizes,
        };
      });
  
      setCartItems(updatedItems);
    } catch (error) {
      console.error("Error parsing cart items from localStorage:", error);
      setCartItems([]); // Reset to an empty array on failure
    }
  }, []);
  
  

  // Calculate total quantity for an item based on its selected sizes
  const calculateTotalQuantity = (item) =>
    item.selectedSizes.reduce((total, size) => total + size.quantity, 0);

  // Calculate total quantity for all items in the cart
  const calculateTotalCartQuantity = () => {
    return cartItems.reduce((total, item) => total + calculateTotalQuantity(item), 0);
  };

  // Update main quantity dynamically
  const updateMainQuantity = () => {
    const updatedItems = cartItems.map((item) => ({
      ...item,
      quantity: calculateTotalQuantity(item),
    }));
    setCartItems(updatedItems);
    localStorage.setItem("cart", JSON.stringify(updatedItems));
  };

  // Handle quantity increase for specific size
  const increaseQuantity = (index, size) => {
    const updatedItems = [...cartItems];
    const item = updatedItems[index];
    const sizeIndex = item.selectedSizes.findIndex((s) => s.size === size);

    if (sizeIndex !== -1) {
      item.selectedSizes[sizeIndex].quantity += 1;
    }

    setCartItems(updatedItems);
    localStorage.setItem("cart", JSON.stringify(updatedItems));
    updateMainQuantity(); // Update total quantity
  };

  

  // Handle quantity decrease for specific size
  const decreaseQuantity = (index, size) => {
    const updatedItems = [...cartItems];
    const item = updatedItems[index];
    const sizeIndex = item.selectedSizes.findIndex((s) => s.size === size);

    if (sizeIndex !== -1 && item.selectedSizes[sizeIndex].quantity > 0) {
      item.selectedSizes[sizeIndex].quantity -= 1;
    }

    setCartItems(updatedItems);
    localStorage.setItem("cart", JSON.stringify(updatedItems));
    updateMainQuantity(); // Update total quantity
  };

  const removeItem = (index) => {
    const updatedItems = cartItems.filter((_, i) => i !== index);
    setCartItems(updatedItems);
    localStorage.setItem("cart", JSON.stringify(updatedItems));
  };
  
  const [showOptions, setShowOptions] = useState(false);

  const handleCheckout = () => {
    if (cartItems.length === 0) {
      alert("Your cart is empty!");
      return;
    }

    setShowOptions(true); // Show options modal
  };

  const handleOptionSelect = (choice) => {
    const total = cartItems
      .reduce((total, item) => {
        return (
          total +
          item.selectedSizes.reduce((sizeTotal, sizeData) => {
            const updatedPrice = ['basic t-shirts', 'nomad', 'pants'].includes(item.name?.toLowerCase())
              ? item.name?.toLowerCase() === 'basic t-shirts'
                ? 19.99
                : item.name?.toLowerCase() === 'nomad'
                ? 55.99
                : 30.79
              : item.price || item.base_price;

            const discountAmount = item.discount
              ? updatedPrice * (item.discount / 100)
              : 0;
            const discountedPrice = updatedPrice - discountAmount;

            return sizeTotal + discountedPrice * sizeData.quantity;
          }, 0)
        );
      }, 0)
      .toFixed(2);

    const whatsappNumber =
      choice === "iraq" ? "+9647774089987" : "+905362887229";

    const message = cartItems
      .map((item) =>
        item.selectedSizes
          .filter((sizeData) => sizeData.quantity > 0)
          .map((sizeData) => {
            const updatedPrice = ['basic t-shirts', 'nomad', 'pants'].includes(item.name?.toLowerCase())
              ? item.name?.toLowerCase() === 'basic t-shirts'
                ? 19.99
                : item.name?.toLowerCase() === 'nomad'
                ? 55.99
                : 30.79
              : item.price || item.base_price;

            const discountAmount = item.discount
              ? updatedPrice * (item.discount / 100)
              : 0;
            const discountedPrice = updatedPrice - discountAmount;

            return `- ${item.name} (${item.color}, Size: ${sizeData.size}) x${sizeData.quantity} = $${(
              updatedPrice * sizeData.quantity
            ).toFixed(2)} ${
              discountAmount > 0
                ? `(Discount: ${item.discount}% - New Price: $${discountedPrice.toFixed(2)})`
                : ""
            }`;
          })
          .join("%0A")
      )
      .filter((line) => line)
      .join("%0A");

    if (message.trim() === "") {
      alert("No items in the cart to checkout!");
      return;
    }

    const fullMessage = `Hello, I would like to order the following items:%0A${message}%0A%0ATotal: $${total}`;
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${fullMessage}`;

    window.open(whatsappUrl, "_blank");
    setShowOptions(false); // Close modal
  };


  
  return (
<div className="flex flex-col items-center justify-center bg-[#FDF5F2] px-3 sm:px-8 pb-2">
  <div className="flex flex-col sm:items-center items-start ml-16 mb-0 mt-0 sm:justify-center w-full">
    <h1 className="text-2xl sm:text-4xl font-bold font-gothamBold text-[#3D291D] mt-4 mb-0">
          Cart
        </h1>
        <IoIosArrowDown className="text-[#3D291D] text-2xl sm:text-4xl mt-2 hidden sm:block" />
      </div>
      {cartItems.map((item, index) => {
          console.log(item);
 // Inside your map of items
const updatedPrice = ['basic t-shirts', 'nomad', 'pants'].includes(item.name?.toLowerCase()) 
  ? (item.name?.toLowerCase() === 'basic t-shirts' ? 19.99 
      : item.name?.toLowerCase() === 'nomad' ? 55.99 
      : 30.79) 
  : item.price || item.base_price;

const discountAmount = item.discount ? updatedPrice * (item.discount / 100) : 0;
const discountedPrice = updatedPrice - discountAmount;


  return (
    <div
      key={index}
      className="w-full max-w-[50rem] h-[12rem] sm:h-[15rem] bg-[#FDF5F2] flex items-center px-0 sm:px-6 relative border-b-2 border-[#3D291D]"
    >
      <div className="w-28 h-28 sm:w-44 sm:h-44 mr-4 sm:mr-12">
        <img
           onClick={() => {
            if (!item.slug) {
              console.error('Slug is undefined:', item);
              return;
            }
            console.log('Navigating to:', item.slug, item.color); // Debug the slug
            handleProductClick(item.slug, item.color);
          }}
          src={
            item.images && item.images[0]
              ? item.images[0].image
              : item.image || "default-image.jpg"
          }
          alt={item.images && item.images[0]?.alt_text || item.name}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="flex-grow">
        <div className="flex justify-between items-center mb-0">
          <h3 className="text-sm sm:text-3xl font-gothamBold font-bold text-[#3D291D]">
            {item.color} {item.name}
          </h3>
          <button
            onClick={() => removeItem(index)}
            className="text-[#3D291D] mr-8 hover:text-[#FF5733]"
          >
            <GoTrash size={20} />
          </button>
        </div>
        <div className="flex items-center mt-2 space-x-3">
  {/* Price Display Logic */}
  {item.discount > 0 ? (
    <>
      {/* Original Price with Line-through */}
      <span className="text-sm sm:text-md font-light font-montserrat text-[#3D291D] line-through">
        ${item.price || item.base_price}
      </span>
      {/* Discounted Price */}
      <span className="text-lg sm:text-2xl font-gothamBlack text-[#7DDED9]">
        ${discountedPrice.toFixed(2)}
      </span>
    </>
  ) : (
    /* No Discount Logic */
    <span className="text-lg sm:text-xl font-light font-montserrat text-[#3D291D]">
      {['basic t-shirts', 'nomad', 'pants'].includes(item.name?.toLowerCase()) ? (
        <>
          {/* Original Price for Specific Items */}
          <span className="text-sm line-through">
            ${item.price || item.base_price}
          </span>
          {/* Adjusted Price for Specific Items */}
          <span className="ml-2 text-[#7DDED9] font-gothamBlack">
            {item.name?.toLowerCase() === 'basic t-shirts' && '$19.99'}
            {item.name?.toLowerCase() === 'nomad' && '$55.99'}
            {item.name?.toLowerCase() === 'pants' && '$30.79'}
          </span>
        </>
      ) : (
        /* Default Price for All Other Items */
        `$${item.price || item.base_price}`
      )}
    </span>
  )}
</div>

        <div className="flex flex-col mt-2">
          {["S", "M", "L"].map((size) => (
            <div
              key={size}
              className="flex items-center justify-start mb-0 sm:mb-4"
            >
              <span className="text-sm sm:text-md font-light font-montserrat text-[#3D291D] w-6">
                {size}
              </span>
              <div className="flex items-center space-x-2 ml-2">
                <button
                  onClick={() => decreaseQuantity(index, size)}
                  className={`w-6 h-4 sm:w-8 sm:h-4 flex items-center justify-center rounded-full border-1 ${
                    item.selectedSizes.find((s) => s.size === size)?.quantity > 0
                      ? "border-[#3D291D] hover:bg-[#FA8F21] active:bg-[#FA8F21]"
                      : "border-gray-300 cursor-not-allowed"
                  } transition`}
                  disabled={
                    !(
                      item.selectedSizes.find((s) => s.size === size)
                        ?.quantity > 0
                    )
                  }
                >
                  <HiOutlineMinus
                    size={14}
                    className="sm:text-[20px]"
                    color="#3D291D"
                  />
                </button>

                <div className="text-sm sm:text-md font-light font-montserrat text-[#3D291D] text-center w-6">
                  {item.selectedSizes.find((s) => s.size === size)?.quantity ||
                    0}
                </div>
                <button
                  onClick={() => increaseQuantity(index, size)}
                  className="w-6 h-4 sm:w-8 sm:h-4 flex items-center justify-center rounded-full border-1 border-[#3D291D] hover:bg-[#FA8F21] active:bg-[#FA8F21] transition"
                >
                  <HiOutlinePlus
                    size={14}
                    className="sm:text-[20px]"
                    color="#3D291D"
                  />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
})}



<div className="flex flex-col w-full max-w-[45rem] mt-4 pr-4 sm:pr-6">
{/* Total Price Section */}
<div className="flex flex-col mb-4 ml-auto">
  {/* Show "Total:" and only show before total if a discount is applied */}
  <span className="text-sm sm:text-lg font-gothamBold text-[#3D291D]">
    Total: 
    {cartItems.some(item => item.discount > 0) ? (
      <span className="flex items-center">
        {/* Before Discount Total (with strikethrough) */}
        <span className="text-xs sm:text-sm line-through mr-2">
          ${cartItems
            .reduce((total, item) => {
              const itemPrice = item.price || item.base_price;
              return total + item.selectedSizes.reduce(
                (sizeTotal, { quantity }) => sizeTotal + itemPrice * quantity,
                0
              );
            }, 0)
            .toFixed(2)} {/* Total before any discounts */}
        </span>
        {/* After Discount Total */}
        <span>
          ${cartItems
            .reduce((total, item) => {
              const itemPrice = item.price || item.base_price;
              const discountPrice = item.discount ? itemPrice * (1 - item.discount / 100) : itemPrice;

              return total + item.selectedSizes.reduce(
                (sizeTotal, { quantity }) => sizeTotal + discountPrice * quantity,
                0
              );
            }, 0)
            .toFixed(2)} {/* Total after discounts */}
        </span>
      </span>
    ) : (
      // Show only "Total" if no discounts
<span>
{cartItems.some((item) =>
  ['basic t-shirts', 'nomad', 'pants'].includes(item.name?.toLowerCase())
) && (
  <span className="text-[#3D291D] text-sm  line-through mr-2">
     ${cartItems
      .reduce((total, item) => {
        let itemPrice = item.price || item.base_price;

        // No hardcoded prices applied here
        return (
          total +
          item.selectedSizes.reduce(
            (sizeTotal, { quantity }) => sizeTotal + itemPrice * quantity,
            0
          )
        );
      }, 0)
      .toFixed(2)}
  </span>
)}



  {/* Show the New Total if there is a hardcoded discount, or just the new total if there's no hardcoded discount */}
  <span className="font-bold text-[#3D291D]">
  ${cartItems
      .reduce((total, item) => {
        let itemPrice = item.price || item.base_price;

        // Apply hardcoded prices for specific items
        if (['basic t-shirts', 'nomad', 'pants'].includes(item.name?.toLowerCase())) {
          if (item.name?.toLowerCase() === 'basic t-shirts') itemPrice = 19.99;
          if (item.name?.toLowerCase() === 'nomad') itemPrice = 55.99;
          if (item.name?.toLowerCase() === 'pants') itemPrice = 30.79;
        }

        // Calculate the total for this item based on selected sizes and quantities
        return (
          total +
          item.selectedSizes.reduce(
            (sizeTotal, { quantity }) => sizeTotal + itemPrice * quantity,
            0
          )
        );
      }, 0)
      .toFixed(2)}
  </span>


</span>



    )}
  </span>
</div>


  {/* Promo Code and Checkout Section */}
  <div className="flex ml-auto">
  <input
        type="text"
        value={promoCode}
        onChange={handlePromoCodeChange}
        placeholder="Promo Code"
        className="w-32 sm:w-45 h-10 sm:h-12 bg-transparent text-sm font-gothamBold text-[#3D291D] mr-2 placeholder-[#3D291D] focus:outline-none"
        style={{
          borderTop: '0',
          borderLeft: '0',
          borderRight: '0',
          borderBottom: '2px solid #3D291D',
          borderRadius: '0', // Ensures no rounded corners
        }}
      />
      <span
        className="text-[#3D291D]"
        onClick={validatePromoCode} // Trigger validation on click
      >
        <FaArrowRight size={10} sm={13} className="-ml-1 mt-3 sm:mt-4" style={{ cursor: 'pointer' }} />
      </span>
      <div className="relative inline-block">
  <button
    onClick={handleCheckout}
    disabled={calculateTotalCartQuantity() === 0}
    className={`w-32 sm:w-36 md:w-40 h-10 sm:h-12 md:h-10 ml-4 sm:ml-8 rounded-3xl text-white text-sm sm:text-lg font-gothamBold transition duration-300 ${
      calculateTotalCartQuantity() === 0
        ? 'bg-[#FA8F21] cursor-not-allowed'
        : 'bg-[#FA8F21] hover:shadow-md'
    }`}
  >
    Check out ({calculateTotalCartQuantity()})
  </button>
  {showOptions && (
    <div
      className="absolute left-0 mt-2 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 p-2 rounded-2xl shadow-md w-40 md:w-56"
      style={{
        backgroundColor: '#FDF5F2',
        color: '#FA8F21',
        fontFamily: 'GothamBold',
        fontWeight: 'bold',
        zIndex: 50, // Ensures the modal appears above other content
      }}
    >
      <button
        className="px-2 md:px-3 py-1 rounded-md "
        onClick={() => handleOptionSelect("worldwide")}
        style={{ color: '#FA8F21' }}
      >
        World Wide
      </button>
      <span
        className="hidden md:inline mt-1"
        style={{ color: '#FA8F21', fontWeight: 'bold' }}
      >
        or
      </span>
      <button
        className="px-2 md:px-3 py-1 rounded-md "
        onClick={() => handleOptionSelect("iraq")}
        style={{ color: '#FA8F21' }}
      >
        Iraq
      </button>
    </div>
  )}
</div>

  </div>
</div>
      <hr className="w-full h-px bg-[#3D291D] mt-8 " />
    </div>
  );
};

export default AddCart;
