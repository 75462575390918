import React, { useState, useEffect } from "react";
import axios from "axios";

const PopupForm = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [countryCodes, setCountryCodes] = useState([]);

  // Fetch country codes from the API
  useEffect(() => {
    const fetchCountryCodes = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        const codes = response.data.map((country) => ({
          name: country.name.common,
          code: country.idd?.root ? country.idd.root + (country.idd.suffixes?.[0] || "") : null,
        })).filter(country => country.code);
        setCountryCodes(codes);
        setCountryCode(codes[0]?.code || ""); // Set the default country code
      } catch (error) {
        console.error("Error fetching country codes:", error);
      }
    };
    fetchCountryCodes();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const data = {
      phone: `${countryCode} ${phone}`,
      email,
      name,
    };

    try {
      await axios.post("https://saarconcept.net/api/events/create/", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setLoading(false);
      alert("Data submitted successfully!");
      setShowPopup(false);
    } catch (err) {
      setLoading(false);
      if (err.response) {
        console.error("Error response:", err.response);

        const errorData = err.response.data;
        let errorMessage = "There were errors in the form submission:\n";

        if (errorData.email) {
          errorMessage += `- Email: ${errorData.email.join(", ")}\n`;
        }
        if (errorData.phone) {
          errorMessage += `- Phone: ${errorData.phone.join(", ")}\n`;
        }
        if (errorData.name) {
          errorMessage += `- Name: ${errorData.name.join(", ")}\n`;
        }

        setError(errorMessage.trim());
      } else {
        setError("Network error. Please try again.");
      }
    }
  };

  return (
    <div>
      <button
        onClick={() => setShowPopup(true)}
        className="px-4 py-2 bg-[#FA8F21] font-gothamBold text-white rounded-3xl"
      >
        Connect with Us
      </button>

      {showPopup && (
        <div
          id="popup-overlay"
          className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50"
        >
          <div className="bg-[#FDF5F2] rounded-3xl border-1 border-[#3D291D] w-full sm:w-96 p-6 relative max-w-xs sm:max-w-md">
            <form onSubmit={handleSubmit}>
              <button
                type="button"
                onClick={() => setShowPopup(false)}
                className="absolute top-4 right-4 text-gray-700 font-bold"
              >
                X
              </button>

              <h2 className="text-2xl text-[#3D291D] font-gothamBold text-center mb-4">Send Your Info</h2>
              <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-gothamLight font-medium text-[#3D291D]">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="mt-1 block w-full px-4 py-2 font-gothamLight border-1 border-[#3D291D] rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#FA8F21] text-[#3D291D]"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block font-gothamLight text-sm font-medium text-[#3D291D]">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="mt-1 block w-full font-gothamLight px-4 py-2 border-1 border-[#3D291D] rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#FA8F21] text-[#3D291D]"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="phone" className="block text-sm font-gothamLight font-medium text-[#3D291D]">
                  Phone
                </label>
                <div className="flex gap-2">
                <select
  value={countryCode}
  onChange={(e) => setCountryCode(e.target.value)}
  className="px-1 py-2 border-1 border-[#3D291D] rounded-3xl text-[#3D291D] bg-[#FDF5F2] focus:outline-none focus:ring-2 focus:ring-[#FA8F21]"
>
                    {countryCodes.map((country) => (
                      <option key={country.code} value={country.code}>
                        {country.code}
                      </option>
                    ))}
                  </select>
                  <input
  type="text"
  id="phone"
  value={phone}
  onChange={(e) => setPhone(e.target.value)}
  required
  className="w-full px-4 py-2 font-gothamLight border-1 border-[#3D291D] rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#FA8F21] text-[#3D291D]"
/>

                </div>
              </div>
              {error && <p className="text-red-600 font-gothamLight text-sm mb-4">{error}</p>}
              <button
                type="submit"
                disabled={loading}
                className={`w-full px-4 py-2 text-white font-gothamBold rounded-3xl focus:outline-none ${loading ? 'bg-gray-400' : 'bg-[#FA8F21] hover:bg-[#FA8F21]'}`}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopupForm;
