import React, { useState, useEffect } from 'react';
import { LuHeart } from 'react-icons/lu';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import WhatsappContact from '../components/contact';
import LoginSignupModal from '../components/LoginSignupModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Spinner = () => (
  <div className="w-10 h-10 border-4 border-orange-500 border-t-transparent rounded-full animate-spin"></div>
);

const ProductDetail = () => {
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]); // To store the list of products
  const [selectedSize, setSelectedSize] = useState('S'); // Default selected size
  const [randomProducts, setRandomProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { slug } = useParams(); // Assumes you have slug-based routing
  const location = useLocation(); // Use location to get query params

  // Function to get query parameters
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };

  const selectedColor = getQueryParam('color'); // Get the color from URL

  // Fetching product list
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://saarconcept.net/api/products-with-filters/');
        setProducts(response.data.products);

        // Set random products after fetching
        const shuffled = [...response.data.products].sort(() => 0.5 - Math.random());
        setRandomProducts(shuffled.slice(0, 4));
      } catch (error) {
        console.error("Error fetching product list:", error);
      }
    };

    fetchProducts();
  }, []);

  
  // Fetching product details based on slug
  useEffect(() => {
    const fetchProduct = async () => {
      if (slug) {
        try {
          const response = await axios.get(`https://saarconcept.net/api/products/${slug}/`);
          setProduct(response.data);
        } catch (error) {
          console.error("Error fetching product details:", error);
        }
      }
    };

    fetchProduct();
  }, [slug]);

  const handleNavigate = (url) => {
    navigate(url);
    setTimeout(() => {
      window.location.reload();
    }, 0);
  };

  const handleNavigate2 = (url) => {
    // Navigate to the desired URL
    navigate(url);
  
    // Scroll to top after navigation has completed
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);  // Set a small timeout to allow the route change to complete first
  };
  

  const handleSizeChange = (size) => {
    console.log(`Selected Size: ${size}`); // Log the selected size
    setSelectedSize(size); // Set the size to the state
  };
  
  const handleAddToCart = () => {
    if (!product || !selectedColor || !selectedSize) {
      toast.error('Please select a valid color and size.');
      return;
    }
  
    const colorDetails = product.product_colors?.find(
      (color) => color.color.name === selectedColor
    );
  
    if (!colorDetails) {
      toast.error('Please select a valid color.');
      return;
    }
  
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
  
    // Check if the product with the same ID and color already exists
    const isProductInCart = cartItems.some(
      (item) =>
        item.id === product.id && item.color === colorDetails.color.name
    );
  
    if (isProductInCart) {
      toast.error('This product is already in your cart.');
      return; // Prevent adding duplicate items
    }
  
    // Add the product to the cart
    const cartItem = {
      id: product.id,
      name: product.name,
      slug: product.slug, 
      color: colorDetails.color.name,
      size: selectedSize, // Store selected size
      base_price: colorDetails.base_price,
      images: colorDetails.images || [],
    };
  
    cartItems.push(cartItem);
    localStorage.setItem('cart', JSON.stringify(cartItems));
  
    // Navigate to the cart after a short delay
    setTimeout(() => {
      navigate('/cart');
    }, 500);
  };
  
  
  const getButtonStyle = (size) => ({
    backgroundColor: selectedSize === size ? "#FA8F21" : "transparent",
    color: selectedSize === size ? "#3D291D" : "#3D291D",
  });
  
  const handleAddToCart2 = (productItem, event) => {
    event.stopPropagation();
  
    if (!productItem) return;
  
    const selectedColor = productItem.product_colors[0]?.color.name;
  
    if (!selectedColor) {
      toast.error('No color available for this product.');
      return;
    }
  
    const colorDetails = productItem.product_colors?.find(
      (color) => color.color.name === selectedColor
    );
  
    if (!colorDetails) {
      toast.error('Invalid color for this product.');
      return;
    }
  
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
  
    const isProductInCart = cartItems.some(
      (item) =>
        item.id === productItem.id &&
        item.color === colorDetails.color.name &&
        item.size === selectedSize
    );
  
    if (isProductInCart) {
      toast.warning('Product is already in the cart!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Exit the function if the product is already in the cart
    }
  
  
    const cartItem = {
      id: productItem.id,
      name: productItem.name,
      color: colorDetails.color.name,
      size: selectedSize,
      base_price: colorDetails.base_price,
      images: colorDetails.images || [],
    };
  
    cartItems.push(cartItem);
    localStorage.setItem('cart', JSON.stringify(cartItems));
    
    setTimeout(() => {
      navigate('/cart');
    }, 500);
  };
  

  if (!product) {
    return (
      <div className='flex items-center justify-center min-h-screen bg-[#FDF5F2]'>
        <Spinner />
      </div>
    );
  }

  // Extract product details to use in JSX
  const { name, description, product_colors, category } = product;

  // Get main image and price based on selected color
  const mainImage = product_colors?.find(color => color.color.name === selectedColor)?.images?.find(image => image.is_feature)?.image;
  const price = product_colors?.find(color => color.color.name === selectedColor)?.base_price;

  // Filter out the selected color to get other colors
  const otherColors = product_colors.filter(color => color.color.name !== selectedColor);

  return (
    <div className="flex flex-col items-center min-h-screen bg-[#FDF5F2] p-4">
         <ToastContainer />
       {/* Conditionally show Red and Blue only when otherColors.length === 1 */}
{otherColors.length === 1 && (
  <div className="flex justify-center md:hidden w-full px-2">
    {/* Red Rectangle - Main Image */}
    <div className="w-44 h-96">
      {mainImage && (
        <img
          src={mainImage}
          alt="Red Main Image"
          className="w-full h-full object-cover"
        />
      )}
    </div>
    
    {/* Blue Rectangle - Alternate Color */}
    <div 
      className="w-44 h-96 cursor-pointer"
      onClick={() => handleNavigate2(`/productDetail/${product.slug}?color=${otherColors[0].color.name}`)}
    >
      {otherColors[0].images.length > 0 && (
        <img
          src={otherColors[0].images[0].image}
          alt={otherColors[0].color.name}
          className="w-full h-full object-cover"
        />
      )}
    </div>
  </div>
)}

{/* Existing container with mx-4 (no red or blue inside here) */}
<div className="flex items-start mx-4 w-full md:w-auto max-w-md md:max-w-full">
  {/* Left Big Square with Conditional Size */}
  <div 
    className={`bg-white border border-gray-300 
      ${otherColors.length === 1 ? 'w-64 h-96' : 'w-96 h-96'} 
      ${otherColors.length === 1 ? 'md:block hidden' : ''}`}
  >
    {mainImage && (
      <img 
        src={mainImage} 
        alt={name} 
        className="w-full h-full object-cover" 
      />
    )}
  </div>

  {/* Right Column with Conditional Box Size */}
  <div className="flex flex-col ml-0">
    {otherColors.length === 1 ? (
      // Only show the right image if there is exactly one color option
      <>
        {/* Larger Image with color options */}
        <div 
          key={0}
          onClick={() => handleNavigate2(`/productDetail/${product.slug}?color=${otherColors[0].color.name}`)}
          className="bg-white border border-gray-300 flex items-center justify-center cursor-pointer w-64 h-96 md:block hidden"
        >
          <div className="flex items-center justify-center w-full h-full">
            {otherColors[0].images.length > 0 && (
              <img
                src={otherColors[0].images[0].image}
                alt={otherColors[0].color.name}
                className="w-full h-full object-cover"
              />
            )}
          </div>
        </div>
      </>
    ) : (
      // Show both images if there are two color options
      otherColors.slice(0, 2).map((colorOption, index) => (
        <div 
          key={index}
          onClick={() => handleNavigate2(`/productDetail/${product.slug}?color=${colorOption.color.name}`)}
          className="bg-white border border-gray-300 flex items-center justify-center cursor-pointer w-44 h-48"
        >
          <div className="flex items-center justify-center w-full h-full">
            {colorOption.images.length > 0 && (
              <img
                src={colorOption.images[0].image}
                alt={colorOption.color.name}
                className="w-full h-full object-cover"
              />
            )}
          </div>
        </div>
      ))
    )}
  </div>



  <div className="hidden sm:block w-[1px] bg-[#3D291D] h-96 mx-4"></div>


        <div className="ml-4 mr-16 hidden md:block">
        <h2 className='font-gothamBold text-[#3D291D]'>
  {selectedColor} {name}
</h2>

<h5 className='font-light font-montserrat text-[#3D291D] mt-2'>
  {/* Base price with strike-through for specific products */}
  <span
    className={`${
      ['basic t-shirts', 'nomad', 'pants'].includes(name?.toLowerCase())
        ? 'text-sm line-through'
        : 'text-lg'
    }`}
  >
    {price}$
  </span>

  {/* New price for specific products */}
  {['basic t-shirts', 'nomad', 'pants'].includes(name?.toLowerCase()) && (
    <span className="ml-2 text-[#7DDED9] font-gothamBlack">
      {name?.toLowerCase() === 'basic t-shirts' && '19.99$'}
      {name?.toLowerCase() === 'nomad' && '55.99$'}
      {name?.toLowerCase() === 'pants' && '30.79$'}
    </span>
  )}
</h5>

          <div className="flex space-x-4 mt-4">
      <button
        className="w-10 h-10 flex items-center justify-center border-2 border-[#3D291D] rounded-full font-gothamLight text-sm transition duration-200"
        style={getButtonStyle("S")}
        onClick={() => handleSizeChange("S")}
        onMouseEnter={(e) => {
          if (selectedSize !== "S") e.currentTarget.style.backgroundColor = "#FA8F21";
        }}
        onMouseLeave={(e) => {
          if (selectedSize !== "S") e.currentTarget.style.backgroundColor = "transparent";
        }}
      >
        S
      </button>
      <button
        className="w-10 h-10 flex items-center justify-center border-2 border-[#3D291D] rounded-full font-gothamLight text-sm transition duration-200"
        style={getButtonStyle("M")}
        onClick={() => handleSizeChange("M")}
        onMouseEnter={(e) => {
          if (selectedSize !== "M") e.currentTarget.style.backgroundColor = "#FA8F21";
        }}
        onMouseLeave={(e) => {
          if (selectedSize !== "M") e.currentTarget.style.backgroundColor = "transparent";
        }}
      >
        M
      </button>
      <button
        className="w-10 h-10 flex items-center justify-center border-2 border-[#3D291D] rounded-full font-gothamLight text-sm transition duration-200"
        style={getButtonStyle("L")}
        onClick={() => handleSizeChange("L")}
        onMouseEnter={(e) => {
          if (selectedSize !== "L") e.currentTarget.style.backgroundColor = "#FA8F21";
        }}
        onMouseLeave={(e) => {
          if (selectedSize !== "L") e.currentTarget.style.backgroundColor = "transparent";
        }}
      >
        L
      </button>
    </div>
          <div className="hidden md:block">
            <h6 className='font-gothamLight text-[#3D291D] mt-4 underline'>Size Details</h6>
            <div className="flex justify-between w-full mt-4">
              <LuHeart
                onClick={() => alert('Added to wishlist')}
                className="text-[#FA8F21] text-3xl" />
              <button
                onClick={handleAddToCart}
                className="bg-[#FA8F21] text-sm font-gothamBold text-white py-2 px-4 rounded-3xl">
                Add to Cart
              </button>
            </div>
            <div className="w-96 h-px bg-[#3D291D] mt-4" />
            <h6 className='font-gothamBold text-[#3D291D] mt-2'>Description</h6>
            <div className='font-gothamMedium text-xs text-[#3D291D] mt-2 whitespace-pre-line overflow-wrap break-word w-96'>
              {description}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile View */}
      <div className="mt-4 md:hidden text-left w-full px-4">
        <h2 className='font-gothamBold text-[#3D291D]'>{selectedColor} {name}</h2>
        <h5 className='font-light font-montserrat text-[#3D291D] mt-2'> <span
    className={`${
      ['basic t-shirts', 'nomad', 'pants'].includes(name?.toLowerCase())
        ? 'text-sm line-through'
        : 'text-lg'
    }`}
  >
    {price}$
  </span>

  {/* New price for specific products */}
  {['basic t-shirts', 'nomad', 'pants'].includes(name?.toLowerCase()) && (
    <span className="ml-2 text-[#7DDED9] font-gothamBlack">
      {name?.toLowerCase() === 'basic t-shirts' && '19.99$'}
      {name?.toLowerCase() === 'nomad' && '55.99$'}
      {name?.toLowerCase() === 'pants' && '30.79$'}
    </span>
  )}</h5>
       <div className="flex space-x-4 mt-4">
       <button
         className="
           w-10 h-10 
           flex items-center justify-center 
           border-2 border-[#3D291D] 
           text-[#3D291D] 
           rounded-full 
           bg-transparent 
           font-gothamLight 
           text-sm 
           transition 
           duration-200 
           hover:!bg-[#FA8F21] 
         "
         onMouseEnter={(e) => {
           e.currentTarget.style.backgroundColor = '#FA8F21';
         }}
         onMouseLeave={(e) => {
           e.currentTarget.style.backgroundColor = 'transparent';
           e.currentTarget.style.color = '#3D291D';
         }}
       >
         S
       </button>
       <button
         className="
           w-10 h-10 
           flex items-center justify-center 
           border-2 border-[#3D291D] 
           text-[#3D291D] 
           rounded-full 
           bg-transparent 
           font-gothamLight 
           text-sm 
           transition 
           duration-200 
           hover:!bg-[#FA8F21] 
         "
         onMouseEnter={(e) => {
           e.currentTarget.style.backgroundColor = '#FA8F21';
         }}
         onMouseLeave={(e) => {
           e.currentTarget.style.backgroundColor = 'transparent';
           e.currentTarget.style.color = '#3D291D';
         }}
       >
         M
       </button>
       <button
         className="
           w-10 h-10 
           flex items-center justify-center 
           border-2 border-[#3D291D] 
           text-[#3D291D] 
           rounded-full 
           bg-transparent 
           font-gothamLight 
           text-sm 
           transition 
           duration-200 
           hover:!bg-[#FA8F21] 
         "
         onMouseEnter={(e) => {
           e.currentTarget.style.backgroundColor = '#FA8F21';
         }}
         onMouseLeave={(e) => {
           e.currentTarget.style.backgroundColor = 'transparent';
           e.currentTarget.style.color = '#3D291D';
         }}
       >
         L
       </button>
     </div>



        <div className="flex justify-between w-full mt-4">
           <LuHeart
            onClick={() => alert('Added to wishlist')}
            className="text-[#FA8F21] text-3xl" />
          <button 
            onClick={handleAddToCart}
            className="bg-[#FA8F21] font-gothamBold text-white py-2 px-4 rounded-3xl">
            Add to Cart
          </button>
         
        </div>

        <div className="w-full h-px bg-[#3D291D] mt-4" /> {/* Separator */}

        <h6 className='font-gothamBold text-[#3D291D] mt-2'>Description</h6>
        <p className='font-gothamMedium text-xs text-[#3D291D] mt-2 whitespace-pre-line'>
          {description}
        </p>
      </div>

      {/* "You might be interested in" Section for mobile view */}
      <div className="mt-4 md:hidden text-center">
        <h3 className="font-gothamBold text-[#3D291D] text-xl">You might be interested in</h3>
      </div>

      {/* "You might be interested in" Section for Desktop */}
      <div className="hidden md:block mt-8 text-center">
        <h3 className="font-gothamBold text-[#3D291D] text-2xl">You might be interested in</h3>
      </div>
      <div className="hidden md:flex flex-wrap justify-center mt-4 gap-3 mb-16"> {/* Increased bottom margin to 16 */}
  {products
    .filter((productItem) => productItem.slug !== product.slug)
    .slice(0, 4)
    .map((productItem, index) => (
      <div
        key={index}
        onClick={() =>
          handleNavigate(
            `/productDetail/${productItem.slug}?color=${productItem.product_colors[0]?.color.name}`
          )
        }
        className="w-60 h-60 flex flex-col p-4 cursor-pointer mb-4" // Card bottom margin
      >
        {/* Image Container */}
        <div className="w-full relative" style={{ paddingBottom: '100%', overflow: 'hidden' }}> {/* Added overflow-hidden */}
          {productItem.product_colors[0]?.images[0]?.image && (
            <img
              src={productItem.product_colors[0].images[0].image}
              alt={productItem.name}
              className="absolute top-0 left-0 w-full h-full object-cover object-center" // Ensures image fills square container
            />
          )}
        </div>

        {/* Product Details */}
        <h4 className="font-gothamBold text-[#3D291D] text-lg self-start mt-2">
  {`${productItem.product_colors[0]?.color.name} ${productItem.name}`}
</h4>

<p className="font-light font-montserrat text-[#3D291D] text-md mt-1 self-start">
  {/* Base price with strike-through for specific products */}
  <span
    className={`${
      ['basic t-shirts', 'nomad', 'pants'].includes(productItem.name?.toLowerCase())
        ? 'text-sm line-through'
        : 'text-lg'
    }`}
  >
    ${productItem.product_colors[0]?.base_price}
  </span>

  {/* New price for specific products */}
  {['basic t-shirts', 'nomad', 'pants'].includes(productItem.name?.toLowerCase()) && (
    <span className="ml-2 text-[#7DDED9] font-gothamBlack">
      {productItem.name?.toLowerCase() === 'basic t-shirts' && '19.99$'}
      {productItem.name?.toLowerCase() === 'nomad' && '55.99$'}
      {productItem.name?.toLowerCase() === 'pants' && '30.79$'}
    </span>
  )}
</p>

        {/* Action Buttons */}
        <div className="flex justify-between items-center w-full mt-auto">
          <button
            onClick={(event) => handleAddToCart2(productItem, event)}
            className="bg-[#FA8F21] font-bold font-gothamBold text-white py-2 px-4 rounded-3xl"
          >
            Add to Cart
          </button>
          <LuHeart className="text-[#FA8F21] text-2xl" />
        </div>
      </div>
    ))}
</div>


<div className="grid grid-cols-2 gap-0 mt-4 md:hidden">
  {products
    .filter((productItem) => productItem.slug !== product.slug)
    .slice(0, 4)
    .map((productItem, index) => (
      <div
        key={index}
        onClick={() =>
          handleNavigate(`/productDetail/${productItem.slug}?color=${productItem.product_colors[0]?.color.name}`)} // Navigate on click
        className="w-full h-full flex flex-col p-2 no-underline cursor-pointer"
      >
        {/* Image Section with fixed aspect ratio */}
        {productItem.product_colors[0]?.images[0]?.image ? (
          <div className="w-full relative" style={{ paddingBottom: '100%' }}> {/* Aspect ratio 1:1 */}
            <img
              src={productItem.product_colors[0].images[0].image}
              alt={productItem.name}
              className="absolute top-0 left-0 w-full h-full object-cover mb-2"
            />
          </div>
        ) : (
          <div className="w-full h-36 bg-gray-600 mb-2" />
        )}
        
        {/* Product Name and Price */}
        <h4 className="font-gothamBold text-[#3D291D] text-base">
          {`${productItem.product_colors[0]?.color.name} ${productItem.name}`}
        </h4>
        <p className="font-light font-montserrat text-[#3D291D] text-sm mt-1">
        <span
    className={`${
      ['basic t-shirts', 'nomad', 'pants'].includes(productItem.name?.toLowerCase())
        ? 'text-sm line-through'
        : 'text-lg'
    }`}
  >
    ${productItem.product_colors[0]?.base_price}
  </span>

  {/* New price for specific products */}
  {['basic t-shirts', 'nomad', 'pants'].includes(productItem.name?.toLowerCase()) && (
    <span className="ml-2 text-[#7DDED9] font-gothamBlack">
      {productItem.name?.toLowerCase() === 'basic t-shirts' && '19.99$'}
      {productItem.name?.toLowerCase() === 'nomad' && '55.99$'}
      {productItem.name?.toLowerCase() === 'pants' && '30.79$'}
    </span>
  )}
        </p>

        {/* Button and Heart Icon */}
        <div className="flex justify-between items-center mt-auto">
          <button
            onClick={(event) => handleAddToCart2(productItem, event)} // Pass the event here
            className="bg-[#FA8F21] text-sm font-bold font-gothamBold text-white py-2 px-3 rounded-3xl"
          >
            Add to Cart
          </button>
          <LuHeart className="text-[#FA8F21] text-2xl" />
        </div>
      </div>
    ))}
</div>


<LoginSignupModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
<div className="fixed bottom-32 right-4 lg:right-8 z-50">
            <WhatsappContact />
          </div> 
  {/* Horizontal Rule with Custom Color */}
  <hr className="w-full h-px bg-[#3D291D] mt-16" />

    </div>
  );
};

export default ProductDetail;
