import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { LuHeart } from 'react-icons/lu';
import LoginSignupModal from './LoginSignupModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Spinner = () => (
  <div className="w-10 h-10 border-4 border-orange-500 border-t-transparent rounded-full animate-spin"></div>
);
const FullBodyOutfit = () => {
  const [outfits, setOutfits] = useState([]);
  const [centerOutfit, setCenterOutfit] = useState(0);
  const [movementDistance, setMovementDistance] = useState(100);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchOutfits = async () => {
      try {
        const response = await fetch('https://saarconcept.net/api/products-with-filters/');
        const data = await response.json();
        const fullOutfits = data.products
          .filter(product => product.slug === 'full-outfit') // Filter for "full outfit" products
          .flatMap(product =>
            product.product_colors.map(color => ({
              image: color.images[0]?.image, // Get the image UR
              name: product.name, // Combine color and product name
              price: color.base_price, // Get the base price
              oldPrice: '130$', // Hardcoded for now
              slug: product.slug,
              url: `https://saarconcept.net/productDetail/${product.slug}?color=${encodeURIComponent(color.color.name)}`,
              color: color.color.name, // Add color explicitly for cart use

            }))
          );
        setOutfits(fullOutfits);
      } catch (error) {
        console.error('Error fetching outfits:', error);
      }
    };

  
    fetchOutfits();
  }, []);
  

  const navigate = useNavigate();
  const addToCart = () => {
    const selectedOutfit = outfits[centerOutfit]; // Get the currently selected outfit
    if (selectedOutfit) {
      console.log('Attempting to add product to cart:');
      console.log('Image:', selectedOutfit.image);
      console.log('Name:', selectedOutfit.name);
      console.log('Price:', selectedOutfit.price);
      console.log('Slug:', selectedOutfit.slug);
      console.log('Color:', selectedOutfit.color);
  
      // Get existing cart items from localStorage
      const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
  
      // Normalize color for comparison (e.g., lowercase for case-insensitive comparison)
      const selectedColor = selectedOutfit.color.toLowerCase();
  
      // Check if the product with the same name and color is already in the cart
      const existingProduct = cartItems.find(
        (item) => item.name === selectedOutfit.name && item.color.toLowerCase() === selectedColor
      );
      
      if (existingProduct) {
        // If a matching product is found with the same color, show a warning and prevent adding
        toast.warning('Product is already in the cart!', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return; // Exit the function if the product with the same color is in the cart
      }
  
      // Add the selected outfit to the cart with default size 'S'
      cartItems.push({
        image: selectedOutfit.image,
        name: selectedOutfit.name, // Includes color and product name
        price: selectedOutfit.price,
        size: 'S', // Default size
        slug: selectedOutfit.slug, // Include the slug
        color: selectedOutfit.color, // Add color explicitly
      });
  
      // Save the updated cart back to localStorage
      localStorage.setItem('cart', JSON.stringify(cartItems));
  
      console.log('Product added to cart:', selectedOutfit.name);
      console.log('Cart updated:', cartItems);
  
      // Show success toast
      toast.success('Product added to cart successfully!');
  
      // Scroll to the top before navigating
      window.scrollTo(0, 0);
  
      // Redirect to the /cart page
      navigate('/cart');
  
      // Reload the page after navigation
      setTimeout(() => {
        window.location.reload();
      }, 100); // Slight delay to ensure navigation completes
    } else {
      console.error('No outfit selected.');
    }
  };
  

  const handleOutfitClick = index => {
    setCenterOutfit(index);
  };

  const handleCenterOutfitClick = () => {
    if (outfits.length > 0) {
      window.location.href = outfits[centerOutfit]?.url;
    }
  };

  const updateMovementDistance = useCallback(() => {
    if (window.innerWidth < 768) {
      setMovementDistance(100);
    } else {
      setMovementDistance(170);
    }
  }, []);

  useEffect(() => {
    updateMovementDistance();
    window.addEventListener('resize', updateMovementDistance);
    return () => window.removeEventListener('resize', updateMovementDistance);
  }, [updateMovementDistance]);

  if (outfits.length === 0) {
    return  <div className="flex justify-center items-center min-h-screen">
    <Spinner />
  </div>; // Show a loading state while fetching data
  }

  return (
    <div id="full-body-outfit" className="flex flex-col items-center py-0 bg-[#FDF5F2]">
        <ToastContainer />
      <h1 className="text-2xl md:text-3xl font-gothamBold text-[#3D291D] mb-16 text-center">
        BUY FULL OUTFIT NOW!
      </h1>
      <div className="relative flex flex-col items-center w-full max-w-screen-lg h-[400px] md:h-[600px] bg-[#FDF5F2] px-4 md:px-0">
        <div className="relative flex justify-center items-center w-full h-full mb-8">
          {outfits.map((outfit, index) => {
            const isCenter = index === centerOutfit;
            const isLeft = index === (centerOutfit - 1 + outfits.length) % outfits.length;
            const isRight = index === (centerOutfit + 1) % outfits.length;

            return (
              <motion.img
                key={index}
                src={outfit.image}
                alt={outfit.name}
                className={`absolute object-cover rounded-lg cursor-pointer ${isCenter ? 'z-20' : 'z-10'} w-[150px] h-[300px] md:w-[250px] md:h-[500px]`}
                initial={{ scale: 0.8 }}
                animate={{
                  scale: isCenter ? 1.2 : 0.8,
                  x: isCenter ? 0 : isLeft ? `-${movementDistance}px` : isRight ? `${movementDistance}px` : '0',
                }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                onClick={isCenter ? handleCenterOutfitClick : () => handleOutfitClick(index)}
              />
            );
          })}
        </div>
        <div className="relative flex flex-col items-center justify-center w-full mt-4 bg-[#FDF5F2]">
          <p className="text-xl md:text-3xl font-gothamBold text-[#3D291D] text-center mt-4">
          {outfits[centerOutfit]?.color} {outfits[centerOutfit]?.name} 
   </p>
          <div className="flex justify-center items-center w-full md:hidden">
            <span className="text-lg font-gothamLight text-[#3D291D] mr-24">{outfits[centerOutfit]?.oldPrice}</span>
            <span className="text-lg font-gothamBold text-[#7DDED9]">{outfits[centerOutfit]?.price}$</span>
          </div>
          <div className="flex justify-center items-center w-full mt-2 md:hidden">
            <button
              onClick={addToCart}
              className="px-4 py-2 bg-[#FA8F21] text-white rounded-full font-gothamBold text-sm mr-16 mt-2"
            >
              Add to Cart
            </button>
            <span
              onClick={() => console.log('Added to Wishlist')}
              className="text-2xl font-gothamBold text-[#FA8F21] mt-2"
            >
              <LuHeart />
            </span>
          </div>
          <span className="hidden md:block text-xl md:text-3xl font-gothamBold text-[#7DDED9] mt-10 absolute md:right-40 mr-48 top-10">
            {outfits[centerOutfit]?.price}$
          </span>
          <span className="hidden md:block text-md md:text-xl font-gothamLight text-[#3D291D] mt-10 absolute md:left-40 ml-48 top-10">
            {outfits[centerOutfit]?.oldPrice}
          </span>
          <button
            onClick={addToCart}
            className="hidden md:block px-4 md:px-6 py-2 bg-[#FA8F21] text-white rounded-full font-gothamBold text-sm md:text-lg mt-2 absolute md:left-40 ml-48 top-32"
          >
            Add to Cart
          </button>
          <span
            onClick={() => console.log('Added to Wishlist')}
            className="hidden md:block text-2xl md:text-3xl font-gothamBold text-[#FA8F21] mt-2 absolute md:right-40 mr-48 top-32"
          >
            <LuHeart />
          </span>
        </div>
      </div>
      <LoginSignupModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <hr className="w-full border-t-2 border-[#3D291D] mt-20 md:mt-32" />
    </div>
  );
};

export default FullBodyOutfit;
