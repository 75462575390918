import React from 'react';
import { IoClose } from 'react-icons/io5'; // Importing a close icon from react-icons

const LoginSignupModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" 
      onClick={onClose} // Close modal when clicking on the background
    >
      <div 
        className="relative bg-white p-6 rounded-3xl w-80 text-center border-2 border-[#3D291D]" 
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
      >
        {/* Close Icon */}
        <button 
          onClick={onClose} 
          className="absolute top-2 right-4 text-[#3D291D] text-xl focus:outline-none" // Adjusted position and size
        >
          <IoClose />
        </button>

        <h2 className="text-xl font-gothamBold font-bold mb-4 text-[#3D291D]">Please Log In or Sign Up</h2>
        <p className="mb-4 text-md font-gothamLight text-[#3D291D]">You need to be logged in to add items to your cart or favorites.</p>
        <button 
          onClick={() => { 
            onClose(); 
            window.location.href = '/login'; // Redirect to login/signup page
          }} 
          className="bg-[#FA8F21] text-md text-white font-gothamBold px-4 py-2 rounded-3xl"
        >
          Log In / Sign Up
        </button>
      </div>
    </div>
  );
};

export default LoginSignupModal;
