import React, { useState, useEffect } from 'react';
import { FaHeart } from 'react-icons/fa'; 
import { useNavigate } from 'react-router-dom';

const Favorite = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState(null);

  const navigate = useNavigate();

  // Fetch the token from localStorage
  useEffect(() => {
    const storedToken = localStorage.getItem('access_token');
    if (storedToken) {
      setToken(storedToken);
    } else {
      navigate('/login');
    }
  }, [navigate]);

  // Fetch favorites from localStorage
  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
    setFavorites(storedFavorites);
    setIsLoading(false);
  }, []);

  // Add a product to favorites
  const addFavorite = (product) => {
    let favorites = JSON.parse(localStorage.getItem('favorites')) || [];

    if (!favorites.some(fav => fav.id === product.id)) {
      favorites.push(product);
      localStorage.setItem('favorites', JSON.stringify(favorites));
      setFavorites(favorites);
      alert('Product added to favorites!');
    } else {
      alert('Product is already in your favorites!');
    }
  };

  // Remove a product from favorites
  const removeFavorite = (productId) => {
    let favorites = JSON.parse(localStorage.getItem('favorites')) || [];
    favorites = favorites.filter(fav => fav.id !== productId);
    localStorage.setItem('favorites', JSON.stringify(favorites));
    setFavorites(favorites);
  };

  // Set mobile state based on window size
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Assuming 768px is the breakpoint for mobile
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check the window size when the component mounts

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="bg-[#FDF5F2] px-4 md:px-40 py-8">
        <div className="flex flex-col items-center mb-4">
          <h1 className="text-3xl md:text-4xl font-bold font-gothamBold text-[#3D291D]">Favorites</h1>
        </div>

        {/* Mobile Layout */}
        {isMobile ? (
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-2">
            {favorites.map((favorite, index) => (
              <div key={index} className="relative flex flex-col items-start p-4 w-full max-w-xs mx-auto border-1 border-[#3D291D]">
                {/* Mobile-specific image size */}
                <div className="relative w-32 h-32">
                  {/* Correctly accessing the first image from the images array */}
                  <img
                    src={favorite.product_colors?.[0]?.images?.[0]?.image || 'path_to_default_image.jpg'}
                    alt={favorite.name}
                    className="object-cover w-full h-full"
                  />
                  <div className="absolute bottom-2 right-2">
                    <FaHeart
                      className="text-[#FA8F21] text-xl cursor-pointer"
                      onClick={() => removeFavorite(favorite.id)}
                    />
                  </div>
                </div>
                {/* Mobile-specific content */}
                <div className="w-full flex justify-between items-center mb-1">
                  <h2 className="text-sm font-bold font-gothamBold text-[#3D291D]">{favorite.name}</h2>
                </div>
                <div className="text-sm font-light font-gothamLight text-[#3D291D] mb-1">
                  <div className="flex justify-between">
                    <p>{favorite.product_colors?.[0]?.color?.name || 'Color not available'}</p>
                    <p>{favorite.category?.name || 'Category not available'}</p>
                  </div>
                </div>
                <div className="w-full flex justify-between items-center mb-1">
                  <p>{favorite.product_colors?.[0]?.base_price}$</p>
                </div>
                <div className="flex justify-between w-full">
                  <button
                    className="mt-0 bg-[#FA8F21] font-bold font-gothamBold text-white py-1 px-2 rounded-3xl text-sm"
                    onClick={() => addFavorite(favorite)}
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-3 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {favorites.map((favorite, index) => (
              <div key={index} className="relative flex flex-col items-start p-4 w-full max-w-xs mx-auto border-1 border-[#3D291D]">
                {/* Desktop-specific image size */}
                <div className="relative w-64 h-64">
                  {/* Correctly accessing the first image from the images array */}
                  <img
                    src={favorite.product_colors?.[0]?.images?.[0]?.image || 'path_to_default_image.jpg'}
                    alt={favorite.name}
                    className="object-cover w-full h-full"
                  />
                  <div className="absolute bottom-2 right-2">
                    <FaHeart
                      className="text-[#FA8F21] text-xl cursor-pointer"
                      onClick={() => removeFavorite(favorite.id)}
                    />
                  </div>
                </div>
                {/* Desktop-specific content */}
              
                <div className="text-lg font-bold font-gothamBold text-[#3D291D]">
                <div className="flex justify-between space-x-2">
  <p>{favorite.product_colors?.[0]?.color?.name || 'Color not available'}</p>
  <p>{favorite.category?.name || 'Category not available'}</p>
</div>

                </div>
    

                <div className="flex justify-between w-full">
                  <button
                    className="mt-0 bg-[#FA8F21] font-bold font-gothamBold text-white py-2 px-3 rounded-3xl text-lg"
                    onClick={() => addFavorite(favorite)}
                  >
                    Add to Cart
                  </button>
                 
  <p className="ml-auto font-gothamLight">{favorite.product_colors?.[0]?.base_price}$</p>

                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Favorite;
