import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './ScrollToTop'; // Import the ScrollToTop component
import Navbar from './components/navbar';
import Login from './pages/login/login';
import SignUp from './pages/signup/signup'; 
import Aboutus from './pages/aboutus/aboutus';
import Footer from './components/footer';
import Categories from './pages/categories';
import Products from './pages/products';
import Favorite from './pages/favorite';
import ProductDetail from './pages/productDetail';
import AddCart from './pages/addCart';
import FiveInOne from './pages/fiveInOne';
import SevenInOne from './pages/sevenInOne';
import Form from './components/form';

const App = () => {
  return (
    <div className="flex flex-col min-h-screen bg-[#FDF5F2]">
      <Router>
        <ScrollToTop />
        <Navbar />
        <div className="flex-grow">
          {/* Routes for different pages */}
          <Routes>
            <Route path="/" element={<Aboutus />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/products" element={<Products />} />
            <Route path="/cart" element={<AddCart />} />
            <Route path="/favorites" element={<Favorite />} />
            <Route path="/5in1" element={<FiveInOne />} />
            <Route path="/7in1" element={<SevenInOne />} />
            <Route path="/form" element={<Form />} />
            <Route path="/productDetail/:slug" element={<ProductDetail />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
