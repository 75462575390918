import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";  // Import react-select
import '../index.css'

const Form = () => {
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [countryCodes, setCountryCodes] = useState([]);

  // Fetch country codes from the API
  useEffect(() => {
    const fetchCountryCodes = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        const codes = response.data.map((country) => ({
          label: `${country.name.common} (${country.idd?.root}${country.idd.suffixes?.[0] || ""})`,
          value: country.idd?.root + (country.idd.suffixes?.[0] || ""),
        })).filter(country => country.value);
        setCountryCodes(codes);
        setCountryCode(codes[0]?.value || ""); // Set the default country code
      } catch (error) {
        console.error("Error fetching country codes:", error);
      }
    };
    fetchCountryCodes();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const data = {
      phone: `${countryCode} ${phone}`,
      email,
      name,
    };

    try {
      await axios.post("https://saarconcept.net/api/events/create/", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setLoading(false);
      alert("Data submitted successfully!");
    } catch (err) {
      setLoading(false);
      if (err.response) {
        console.error("Error response:", err.response);

        const errorData = err.response.data;
        let errorMessage = "There were errors in the form submission:\n";

        if (errorData.email) {
          errorMessage += `- Email: ${errorData.email.join(", ")}\n`;
        }
        if (errorData.phone) {
          errorMessage += `- Phone: ${errorData.phone.join(", ")}\n`;
        }
        if (errorData.name) {
          errorMessage += `- Name: ${errorData.name.join(", ")}\n`;
        }

        setError(errorMessage.trim());
      } else {
        setError("Network error. Please try again.");
      }
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      width: '100%',
      padding: '4px 8px',
      borderRadius: '9999px',
      border: '1px solid #3D291D',
      fontFamily: 'Gotham, sans-serif',
      fontSize: '16px',
      color: '#3D291D',
      backgroundColor: '#FDF5F2',
      '&::after': {
        content: '""', // Add some extra space for autofill background to reset
        visibility: 'hidden',
      },
      '&:focus': {
        backgroundColor: '#FDF5F2',  // Keep it normal on focus
      },
    }),
    menu: (base) => ({
      ...base,
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#F4E1D2',
      padding: '8px 0',
    }),
    option: (base) => ({
      ...base,
      padding: '8px 15px',
      fontSize: '14px',
      color: '#3D291D',
      '&:hover': {
        backgroundColor: '#FA8F21',
        color: '#fff',
      },
    }),
  };

  return (
    <div className="bg-[#FDF5F2] p-4 flex flex-col items-center justify-center py-8">
      <h2 className="text-2xl text-[#3D291D] font-gothamBold text-center mb-8">
        Send Your Info
      </h2>

      <form onSubmit={handleSubmit} className="w-full sm:w-96 p-6 bg-[#FDF5F2] rounded-3xl border-2 border-[#3D291D]">
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-gothamLight font-medium text-[#3D291D]">
            Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="mt-1 block w-full px-4 py-2 font-gothamLight border-1 border-[#3D291D] rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#FA8F21] text-[#3D291D]"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block font-gothamLight text-sm font-medium text-[#3D291D]">
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="mt-1 block w-full font-gothamLight px-4 py-2 border-1 border-[#3D291D] rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#FA8F21] text-[#3D291D]"
          />
        </div>
        <div className="mb-4 flex gap-2 items-center">
          <div className="w-1/3">
            <label htmlFor="countryCode" className="block text-sm font-gothamLight font-medium text-[#3D291D]">
              Code
            </label>
            <Select
              options={countryCodes}
              value={countryCodes.find(code => code.value === countryCode)}
              onChange={(selectedOption) => setCountryCode(selectedOption.value)}
              className="react-select-container"
              classNamePrefix="react-select"
              styles={customStyles}
            />
          </div>
          <div className="w-2/3">
            <label htmlFor="phone" className="block text-sm font-gothamLight font-medium text-[#3D291D]">
              Phone
            </label>
            <input
              type="text"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              className="w-full px-4 py-2 font-gothamLight border-1 border-[#3D291D] rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#FA8F21] text-[#3D291D]"
            />
          </div>
        </div>
        {error && <p className="text-red-600 font-gothamLight text-sm mb-4">{error}</p>}
        <button
          type="submit"
          disabled={loading}
          className={`w-full px-4 py-2 text-white font-gothamBold rounded-3xl focus:outline-none ${loading ? 'bg-gray-400' : 'bg-[#FA8F21] hover:bg-[#FA8F21]'}`}
        >
          {loading ? "Submitting..." : "Submit"}
        </button>
      </form>
      <hr className="w-full h-px bg-[#3D291D] mt-8 " />
    </div>
  );
};

export default Form;
