import React, { useState, useEffect } from 'react';
import { IoIosClose } from 'react-icons/io';
import or from './Vector 44.png';
import our from './Vector 19.png';
import { useNavigate } from 'react-router-dom';

const Popup = ({ data, onClose, isOpen }) => {

  const navigate = useNavigate();

  useEffect(() => {
    console.log('Popup rendered');
  }, []);

  if (!isOpen) return null; 

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div 
        className="bg-[#FDF5F2] rounded-3xl p-4 w-11/12 max-w-2xl relative max-h-[90vh] overflow-y-auto" 
        style={{ backgroundColor: '#FDF5F2', opacity: 0.98 }}
      >
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-3xl text-[#3D291D] hover:text-[#FA8F21] z-10"
        >
          <IoIosClose />
        </button>

        <div className="flex flex-col items-center">
          {/* Desktop 'Our Offers' */}
          <div className="hidden md:block relative mb-4 w-30 h-10">
            {/* <img
              src={our}
              alt="Image with text"
              className="w-full h-full object-cover rounded-full"
            /> */}
            <span className="absolute inset-0 flex items-center justify-center text-center text-xl font-gothamBold text-[#3D291D]">
              Our offers!!
            </span>
          </div>

          {/* Mobile 'Our Offers' */}
          <div className="md:hidden relative mb-4 w-22 h-10">
            {/* <img
              src={our}
              alt="Image with text"
              className="w-full h-full object-cover rounded-full"
            /> */}
            <span className="absolute inset-0 flex items-center justify-center text-center text-sm font-gothamBold text-[#3D291D]">
              Our offers!!
            </span>
          </div>

          {/* Desktop Layout */}
          <div className="hidden md:flex flex-row items-center justify-center space-x-6 w-full">
          <a
      href="https://saarconcept.net/productDetail/nomad?color=Off%20white"
      className="bg-[#FA8F21] text-[#3D291D] px-6 py-2 rounded-full font-gothamBold no-underline inline-block"
    >
      The Jacket
    </a>

            <div className="relative flex items-center">
              {/* <img
                src={or}
                alt="Image with OR"
                className="w-18 h-8 object-cover rounded-full"
              /> */}
              <span className="absolute inset-0 flex items-center justify-center text-center text-[#3D291D] font-gothamBold">
                OR
              </span>
            </div>

            <div className="flex flex-row space-x-4">
              {/* Desktop Box 1 */}
              <div className="border-2 border-[#3D291D] font-gothamBold rounded-3xl p-3 w-[170px] flex flex-col">
                <div className="flex flex-col items-center mb-2">
                  <p className="text-[#3D291D] text-lg">5 in 1</p>
                  <p className="text-[#3D291D] text-base mt-0 mb-0">Full Jacket</p>
                </div>

                <div className="flex flex-col items-center text-center">
                  <p className="text-xs text-[#3D291D] mb-1">X1 EXTRA Sleeves and Pop bag</p>
                  <p className="text-[10px] text-[#3D291D] font-gothamLight">
                    Extras should be the same color
                  </p>
                </div>

                <div className="border-t border-[#3D291D] my-2" />
                
                <div>
                  <div className="flex justify-between items-center">
                    <p className="text-xs text-[#3D291D] font-gothamLight">129.96$</p>
                    <p className="text-base text-[#7DDED9] font-gothamBold">99.99$</p>
                  </div>
                  <div className="flex justify-center mt-1">
                  <button
  className="bg-[#FA8F21] text-[#FDF5F2] px-2 py-1 rounded-3xl font-gothamBold text-sm"
  onClick={() => {
    window.location.href = 'https://saarconcept.net/5in1'; // Navigate directly to the 5in1 page
  }}
>
  Choose
</button>

                  </div>
                </div>
              </div>

              {/* Desktop Box 2 */}
              <div className="border-2 border-[#3D291D] font-gothamBold rounded-3xl p-3 w-[170px] flex flex-col">
                <div className="flex flex-col items-center mb-2">
                  <p className="text-[#3D291D] text-lg">7 in 1</p>
                  <p className="text-[#3D291D] text-base mt-0 mb-0">Full Jacket</p>
                </div>

                <div className="flex flex-col items-center text-center">
                  <p className="text-xs text-[#3D291D] mb-1">X1 EXTRA Sleeves and Pop bag</p>
                  <p className="text-[10px] text-[#3D291D] font-gothamLight">
                    Extras should be the same color
                  </p>
                </div>

                <div className="border-t border-[#3D291D] my-2" />
                
                <div>
                  <div className="flex justify-between items-center">
                    <p className="text-xs text-[#3D291D] font-gothamLight">174.94$</p>
                    <p className="text-base text-[#7DDED9] font-gothamBold">129.99$</p>
                  </div>
                  <div className="flex justify-center mt-1">
                  <button
  className="bg-[#FA8F21] text-[#FDF5F2] px-2 py-1 rounded-3xl font-gothamBold text-sm"
  onClick={() => {
    window.location.href = 'https://saarconcept.net/7in1'; // Navigate directly to the 7in1 page
  }}
>
  Choose
</button>

                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Mobile Layout */}
          <div className="md:hidden flex flex-col items-center space-y-4 w-full">
          <a
      href="https://saarconcept.net/productDetail/nomad?color=Off%20white"
      className="bg-[#FA8F21] text-[#3D291D] px-4 py-1 rounded-full font-gothamBold text-sm no-underline inline-block"
    >
      The Jacket
    </a>

            <div className="relative flex items-center max-sm:w-30 max-sm:h-12">
              {/* <img
                src={or}
                alt="Image with OR"
                className="w-18 h-8 object-cover rounded-full max-sm:w-18 max-sm:h-10"
              /> */}
              <span className="absolute inset-0 flex items-center justify-center text-center text-[#3D291D] font-gothamBold max-sm:text-sm">
                OR
              </span>
            </div>

            <div className="flex flex-col space-y-4 w-full px-4">
              {/* Mobile Box 1 */}
              <div className="border-2 border-[#3D291D] font-gothamBold rounded-3xl p-2 flex flex-col">
                <div className="flex justify-between items-center mb-0">
                  <p className="text-[#3D291D] text-base">Full Jacket</p>
                  <p className="text-[#3D291D] text-2xl">5 in 1</p>
                </div>

                <div className="flex flex-col text-left">
                  <p className="text-xs text-[#3D291D] mb-1">X1 EXTRA Sleeves and Pop bag</p>
                  <p className="text-[10px] text-[#3D291D] font-gothamLight">
                    Extras should be the same color
                  </p>
                </div>

        
                
                <div>
                  <div className="flex justify-between items-center">
                    <p className="text-xs text-[#3D291D] font-gothamLight">129.96$</p>
                    <p className="text-base text-[#7DDED9] font-gothamBold">99.99$</p>
                  </div>
                  <div className="flex justify-center mt-1">
                  <button
  className="bg-[#FA8F21] text-[#FDF5F2] px-2 py-1 rounded-3xl font-gothamBold text-sm"
  onClick={() => {
    window.location.href = 'https://saarconcept.net/5in1'; // Navigate directly to the 5in1 page
  }}
>
  Choose
</button>

                  </div>
                </div>
              </div>

              {/* Mobile Box 2 */}
              <div className="border-2 border-[#3D291D] font-gothamBold rounded-3xl p-2 flex flex-col">
                <div className="flex justify-between items-center mb-0">
                  <p className="text-[#3D291D] text-base">Full Jacket</p>
                  <p className="text-[#3D291D] text-2xl">7 in 1</p>
                </div>

                <div className="flex flex-col text-left">
                  <p className="text-xs text-[#3D291D] mb-1">X2 EXTRA Sleeves and Pop bag</p>
                  <p className="text-[10px] text-[#3D291D] font-gothamLight">
                    Extras can be diffrent color
                  </p>
                </div>

              
                
                <div>
                  <div className="flex justify-between items-center">
                    <p className="text-xs text-[#3D291D] font-gothamLight">174.94$</p>
                    <p className="text-base text-[#7DDED9] font-gothamBold">129.99$</p>
                  </div>
                  <div className="flex justify-center mt-1">
                 <button
  className="bg-[#FA8F21] text-[#FDF5F2] px-2 py-1 rounded-3xl font-gothamBold text-sm"
  onClick={() => {
    window.location.href = 'https://saarconcept.net/7in1'; // Navigate directly to the 7in1 page
  }}
>
  Choose
</button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;